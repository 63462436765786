import React from 'react'
import './index.css'
import { Result } from 'antd'


export function ErrorPage() {
    return (
        <Result title="Site under construction" />
    )
}

