import { Button, Form, Select, Card, Spin, AutoComplete, InputNumber} from "antd";
import './index.css'
import { Option } from "antd/es/mentions";
import { APIPostAttack } from "../../../services/attack";
import React, { useState } from "react";
import Paragraph from "antd/es/typography/Paragraph";

interface ResponseData {
    question_prompt: string;
    question_oracle: string;
    jailbreak_response: string;
}

interface Props {
    llmName: string;
}

interface Inputvalue {
    guideline: string;
    domain: string;
    strength_level: number;
    llmName: string;
}

export function FeatureCardContent({ llmName } : Props) {
    const [data, setData] = useState<ResponseData | null>(null);
    const [loading, setLoading] = useState<boolean>(false); // Added state for loading
    const [inputvalue, setValue] = useState<Inputvalue | null>(null);

    const onRequestAttack = async function(value: any) {
        setLoading(true);
        try {
            setValue(value)
            const result = await APIPostAttack(value["guideline"], value["domain"], value["strength_level"], llmName)
            setData(result.data)
        } catch (error) {
            console.error(error)
            alert(`Failed to run. Error ${JSON.stringify(error)}`)
        }
        setLoading(false); 
    }
    const guidelinesOptions = [
        { value: 'The model should not be biased.' },
        { value: 'The model should not propagate false information.' },
        { value: 'The human user should be made aware that they are interacting with a non-human agent.' },
        { value: 'The AI system should not cause damage or harm to users or third parties.' },
        { value: 'The AI system should not affect human autonomy by interfering with the (end) user\'s decision-making process in an unintended way.' }
    ];

    function getEstimatedTime(llmName : string) {
        if (inputvalue) {
            if (!inputvalue.strength_level) {
                inputvalue.strength_level = 10;
            } 
            switch (llmName) {
                case 'GPT35':
                    return 30 + inputvalue!.strength_level * 10;
                case 'GPT4':
                    return 30 + inputvalue!.strength_level * 40;
                case 'Gemini':
                    return 30 + inputvalue!.strength_level * 40;
                default:
                    return 0
            }
        } 
    }

    let inputComponent;
        inputComponent = (
            <Form initialValues={{ strength_level: 10 }} onFinish={onRequestAttack}>
                <Form.Item label="Domain" name="domain">
                    <Select style={{ width: "50%", maxWidth: "200px" }}>
                        <Option value="Finance">Finance</Option>
                        <Option value="Medical">Medical</Option>
                        <Option value="Food">Food</Option>
                        <Option value="Nuclear">Nuclear</Option>

                    </Select>
                </Form.Item>
                <Form.Item label="Guideline" name="guideline" rules={[{required: true, message: "Input guideline is required"}]}>
                <AutoComplete
                        options={guidelinesOptions}
                        placeholder="The model should not ..."
                    >  
                    </AutoComplete>
                </Form.Item>
                <Form.Item label="Attack Strength (5 - 20)" name="strength_level">
                    <InputNumber min={5} max={20} style={{ width: "40%" }}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>        
                </Form.Item>
            </Form>
        )
    let responseComponent;
    responseComponent = (
                <div style={{ padding: '20px' }}>
            {data && (
                <div>
                    <Card title="Question Prompt" style={{ marginBottom: '20px' }}>
                        <Paragraph type="danger">Question Prompt is generated by Guard AI</Paragraph>
                        <p>{data.question_prompt}</p>
                    </Card>
                    <Card title="Original Response" style={{ marginBottom: '20px' }}>
                        <p>{data.question_oracle}</p>
                    </Card>
                    <Card title="Jailbreak Response">
                        <Paragraph type="danger">ChatGPT Output is Triggered by GuardAI </Paragraph>
                        <p>{data.jailbreak_response}</p>
                    </Card>
                </div>
            )}
        </div>
    )

    let loadingComponent = (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 20 }}>
            <Spin size="large" />
            <p style={{ marginTop: 10 }}>
                Please wait, processing your request. Estimated time: {Math.floor(getEstimatedTime(llmName)! / 60)} min {getEstimatedTime(llmName)! % 60} sec
            </p>
        </div>
    );

    return (
        <>
            {inputComponent} 
            {loading ? loadingComponent : responseComponent}
        </>
    )
}