import { Space, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";

const { Link } = Typography

export function Introduction() {

    return (
        <div className="intro-container">
        <Typography>
            <Title> Guard AI </Title> 
            <Title level={4}> —— Safeguard your AI with EU-compliant vulnerability detection and defense.  </Title> 
            <Space />
            <Paragraph> 
                This demo is an example of how Guard AI detects vulnerabilities in large language models defined by EU AI Act. 
            </Paragraph>

            <Paragraph type="danger"> 
                Disclaimer: Responses are generated by ChatGPT. This website displays these responses to
                raise security awareness of the public and does not intend to do any harm. We are not
                responsible for any damage caused by the output of the models 
            </Paragraph>

            <Title level={4}> 
                Example guideline (Copy this to the input box, or provide your own): 
            </Title>
            <Paragraph> 
                Does the AI system interact with decisions by human (end) users (e.g. recommended actions or decisions to take, presenting of options)?
            </Paragraph>

            <Title level={4}> 
                More guidelines:
            </Title>
            <Paragraph>
                <Link href="https://www.aepd.es/sites/default/files/2019-12/ai-ethics-guidelines.pdf">
                    EU AI Ethics Guidelines
                </Link>
            </Paragraph>
            <Paragraph>
                <Link href="https://eur-lex.europa.eu/resource.html?uri=cellar:e0649735-a372-11eb-9585-01aa75ed71a1.0001.02/DOC_1&format=PDF">
                    EU AI Act
                </Link>
            </Paragraph>
        </Typography>
        </div>
    )
}